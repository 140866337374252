<template>
  <van-col
    @click="$emit('chooseHotel')"
    :class="'pay-btn '+(info.isOk>=standard.length&&banCorp.includes(corp)?'disable-btn':'')"
    >
        <div :class="'double-btn '+(info.paymentType=='预付'?'blue':'warning')+'-border'">
            <van-button
                :type="info.paymentType=='预付'?'info':'warning'"
                size="mini"
                style="padding:0 8px"
            >{{info.paymentType=='预付'?'预付':'到付'}}</van-button>
            <span>￥{{info.averageRate}}</span>
        </div>
  </van-col>
</template>

<script>
import {BanCorp} from "@/common/config"
export default {
  name: "PayBtn",
  props: ['info','standard','corp'],
    data() {
    return {
      activeNames: [], // 已激活的折叠面板
      banCorp: BanCorp // 超标不可订
    };
  },
};
</script>

<style lang="less" scoped>
@import '../../../styles/theme.less';
.warning-border {
  color: #ff976a;
  border: 1px solid #ff976a;
}
.blue-border {
  color: @blue;
  border: 1px solid @blue;
}
.pay-btn {
  align-self: center;
}
.double-btn {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px;
}
</style>