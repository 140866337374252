<template>
  <!-- 酒店的票面 -->
  <div class="hotelList">
    <van-collapse v-model="activeNames">
      <van-collapse-item v-for="(ticketInfo,i) in list" :key="i" :disabled="ticketInfo.ratePlans.length==1" :labelClass="'contbk'">
        <!-- 图片 -->
        <template #icon>
          <van-image
            class="dtail-img"
            :error-icon="error_img"
            :src="ticketInfo.imageUrl"
            width="5rem"
            height = "auto"
          />
        </template>
        <!-- 详情 -->
        <template #title>
          <!-- 文本 - 第一行 - 超标情况 -->
          <div class="hor">
            <el-tooltip
              class="item"
              effect="dark"
              :content="ticketInfo.name"
              placement="top"
              :hide-after="3000"
            >
              <h5 v-if="ticketInfo.ratePlans.length==1" class="van-ellipsis over-text list-text-big">{{ticketInfo.name}}</h5>
              <h5 v-else class="van-ellipsis list-text-big">{{ticketInfo.name}}</h5>
            </el-tooltip>
            <template v-if="ticketInfo.ratePlans.length==1">
              <Excess :ticketInfo="ticketInfo.ratePlans[0]" :standard="standard" :capacity="ticketInfo.capacity"/>
            </template>
          </div>

          <!-- 文本 - 第二行 - 基本信息-->
          <van-row class="list-text">
            <span v-if="ticketInfo.bedType">{{ticketInfo.bedType}}</span>
            <span v-if="ticketInfo.area">{{ticketInfo.area}}m²</span>
            <span v-if="ticketInfo.windows">{{ticketInfo.windows}}</span>
          </van-row>

          <!-- 文本 - 第三行 - 取消，余量等提示信息-->
          <span
            v-if="ticketInfo.ratePlans.length>1"
            :class="'text-sm '+(ticketInfo.ratePlans[0].refundType=='不可取消'?'red':'blue')"
          >{{ticketInfo.ratePlans[0].refundType}}</span>
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            v-else
            :content="ticketInfo.ratePlans[0].refundDesc"
            >
            <span
              :class="'text-sm '+(ticketInfo.ratePlans[0].refundType=='不可取消'?'red':'blue')"
            >{{ticketInfo.ratePlans[0].refundType}}&nbsp;<van-icon name="chat-o" /></span>
          </el-tooltip>

          <!-- 文本 - 第四行 - 特殊规则 -->
          <van-col>
            <p class="special list-text" v-if="ticketInfo.ratePlans[0].limit">
              <van-icon class="special-icon" name="warning-o" />
              &nbsp;{{ticketInfo.ratePlans[0].limit}}
            </p>
          </van-col>
        </template>
        <!-- 右边按钮或者下拉 -->
        <template #right-icon>
          <PayBtn v-if="ticketInfo.ratePlans.length==1" :info="ticketInfo.ratePlans[0]" :standard="standard" :corp="corp"
            @chooseHotel="chooseHotel(ticketInfo)"/>
          <div v-else class="price-cont">
            <span class="red list-title">￥{{ticketInfo.ratePlans[0].nightlyRates[0].price}}</span>起
            <van-icon name="play" :class="'transition open-icon '+(activeNames.includes(i)?'open':'')" />
          </div>
        </template>

        <!-- 展开条目 -->
        <template v-if="ticketInfo.ratePlans.length>1">
          <div v-for="(item,i) in ticketInfo.ratePlans" :key="i" class="child-cont">
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                v-if="item.refundDesc"
                :content="item.refundDesc"
                >
                <span class="van-ellipsis text-sm">{{item.refundDesc}}</span>
              </el-tooltip>
              <van-row class="list-text">
                <span v-if="item.breakfast">{{item.breakfast}}</span>
                <span v-if="item.refundType" :class="item.refundType=='不可取消'?'red':'blue'">{{item.refundType}}</span>
              </van-row>
              <Excess :ticketInfo="item" :standard="standard" :capacity="ticketInfo.capacity"/>
              <p class="warning list-text" v-if="item.limit">
                <van-icon class="special-icon" name="warning-o" />
                &nbsp;{{item.limit}}
              </p>
            </div>
            <PayBtn :info="item" :standard="standard" :corp="corp" @chooseHotel="chooseHotel(ticketInfo,i)"/>
          </div>
        </template>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import PayBtn from "./PayBtn";
import Excess from "./Excess";
import {BanCorp, BlackList} from "@/common/config"
export default {
  name: "HotelItem",
  props: {
    corp: String,
    list: Array,
    standard: Array
  },
  components:{PayBtn,Excess},
  data() {
    return {
      error_img: require("../../../assets/NullHotel.png"),
      banCorp: BanCorp, // 超标不可订
      blackList: BlackList, // 黑名单里的服务商 不显示剩余房间量
      activeNames: [],
    };
  },
  methods: {
    chooseHotel(room,i=0) {
      let temp = JSON.parse(JSON.stringify(room))
      temp.ratePlans = [room.ratePlans[i]]
      this.$emit("childClick", temp);
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../../styles/theme.less';
.van-ellipsis{
  margin-bottom: -4px;
  max-width: calc(100vw - 120px);
  display: inline-block;
}
.hor{
  display: flex;
  align-items: center;
}
.list-text{
  padding: 0;
  span{
    padding-right: 6px;
  }
}
.price-cont{
  display: flex;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.hotelList{
  .open-icon{
    font-size: 10px;
    height: 18px;
    width: 18px;
    transform: rotateZ(90deg) scale(0.7);;
    border-radius: 50%;
    background: @blue;
    color: #fff;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .open{
    transform: scale(0.7) rotateZ(-90deg);
  }
}
.child-cont{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid @gray-3;
  margin-bottom: 10px;
  padding-bottom: 10px;
  .list-text{
    padding: 4px 0;
  }
  .warning {
    padding: 0;
    .special-icon {
      transform: translateY(1px);
    }
  }
}
.contbk{
  background-color: @gray-1;
  border-radius: 8px;
}
.disable-btn {
  filter: grayscale(1);
  opacity: 0.66;
}
.hotelList {
  padding: 0 16px;
  background-color: #fff;
}
.dtail-img {
  padding-right: 6px;
}
.room-item {
  padding: 6px 0;
  border-bottom: 1px solid @gray-3;
}
.tag {
  font-size: 10px;
  top: 0;
}
.list-title {
  padding-top: 0;
  display: flex;
  align-items: center;
}
.over-text {
  max-width: calc(100vw - 260px);
  padding-right: 2px;
  display: inline-block;
}
</style>
<style lang="less">
@import '../../../styles/theme.less';
.hotelList .van-image__error {
  position: relative;
}
.hotelList {
  .van-icon__image {
    width: 100%;
    height: auto;
  }
}
.child-bed {
  border-top: 1px solid @gray-3;
  margin-top: 0.6rem;
  padding-top: 0.6rem;
}
.child-tag {
  margin-bottom: 0.4rem;
}
.hotelList{
  .dtail-img{
    img{
        height: auto !important;
      border-radius: 4px;
      min-height: 4rem;
    }
  }
  .van-collapse-item__content{
    background-color: @gray-1;
  }
  .van-collapse-item>.van-cell{
    padding-left: 0;
  }
}
</style>