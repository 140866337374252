<template>
    <van-tag v-if="status=='全员合规'" class="tag" plain type="success">全员合规</van-tag>
    <van-tag v-else-if="status=='全员超标'" class="tag" plain type="danger">全员超标</van-tag>
</template>

<script>
import {standardJudge} from "@/services/hotel"
export default {
  name: "Excess",
  props: ['ticketInfo','standard','capacity'],
  data() {
    return {
      status: standardJudge(this.ticketInfo.nightlyRates,this.standard,this.capacity).status // 超标状态
    }
  },
};
</script>