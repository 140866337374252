<template>
  <div class="detail-hotel">
    <div style="position:relative">
      <van-image
        @click.stop="$emit('preview')"
        width="100vw"
        :src="(detail.images&&(0 in detail.images))?detail.images[0].bigImageUrl:hotelticket.hotelPic"
        class="img-cont"
      />
      <div class="banner-title">
        <p class="banner-cont">
          <van-icon style="font-size:14px;padding-right:2px" name="photo-o" />
          {{(detail.images||[]).length}}张
        </p>
      </div>
    </div>
    <h4 class="title-font" is-link @click="cons">
      {{hotelticket.chineseName}}
      <span style="width:4px"></span>
      <Logo :corp="hotelticket.corp" plain />
    </h4>
    <van-cell class="auto-cell" size="large" title-class="detailtext">
      <template #title>
        <a
          class="list-text-big"
          :href="'https://uri.amap.com/marker?position='+hotelticket.longitude+','+hotelticket.latitude+'&name='+hotelticket.chineseName+'&callnative=1'"
        >地址：{{hotelticket.address}}</a>
      </template>
      <template #default>
        <a
          :href="'https://uri.amap.com/marker?position='+hotelticket.longitude+','+hotelticket.latitude+'&name='+hotelticket.chineseName+'&callnative=1'"
        >
          <van-icon name="location" class="van-cell__right-icon icon-btn blue" />
        </a>
        <a
          :href="'tel:'+hotelticket.tel"
          v-if="/(\d{2,5}-\d{7,8}$)|(^13\d{9}$)|(^15\d{9}$)|(^18\d{9}$)/.test( hotelticket.tel.trim())"
        >
          <van-icon name="phone" class="van-cell__right-icon icon-btn blue" />
        </a>
        <van-icon
          v-else
          name="phone"
          class="van-cell__right-icon icon-btn blue"
          @click="openPhone(hotelticket.tel)"
        />
      </template>
    </van-cell>

    <!-- 弹出层 -->
    <van-action-sheet v-model="show" title="酒店信息" class="sheet-cont">
      <van-collapse v-model="activeNames">
        <van-collapse-item title="简介" name="1" v-if="detail.introEditorCn">
          <span class="indent">{{detail.introEditorCn}}</span>
        </van-collapse-item>
        <van-collapse-item title="便利设施" name="2" v-if="detail.generalAmenitiesCn">
          <span class="indent">{{detail.generalAmenitiesCn}}</span>
        </van-collapse-item>
        <van-collapse-item title="会议设施" name="3" v-if="detail.meetingAmenities">
          <span class="indent">{{detail.meetingAmenities}}</span>
        </van-collapse-item>
        <van-collapse-item title="休闲设施" name="4" v-if="detail.recreationAmenities">
          <span class="indent">{{detail.recreationAmenities}}</span>
        </van-collapse-item>
        <van-collapse-item title="交通设施" name="5" v-if="detail.traffic">
          <span class="indent">{{detail.traffic}}</span>
        </van-collapse-item>
        <van-collapse-item title="服务" name="6" v-if="detail.serviceAmenities">
          <span class="indent">{{detail.serviceAmenities}}</span>
        </van-collapse-item>
      </van-collapse>
    </van-action-sheet>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
export default {
  name: "DetailHotelHead",
  components: { Logo },
  data() {
    return {
      show: false,
      activeNames: ['1','2','3','4','5','6'],
      traffic: true, //交通设施折叠开关
      introEditorCn: true,
      recreationAmenities: true,
      meetingAmenities: true,
      serviceAmenities: true,
      generalAmenitiesCn: true,
    };
  },
  props: ["hotelticket", "detail"],
  activated(){
    this.show = false
  },
  methods: {
    cons() {
      if (
        this.detail.introEditorCn ||
        this.detail.generalAmenitiesCn ||
        this.detail.meetingAmenities ||
        this.detail.recreationAmenities ||
        this.detail.traffic ||
        this.detail.serviceAmenities
      )
      this.show = true;
    },
    openPhone(phone) {
      this.$dialog.alert({
        title: "供应商电话",
        message: phone,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../styles/theme.less';
.img-cont {
  max-height: 50vh;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
}
.banner-title {
  padding: 0.4rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  z-index: 4;
  color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: @font-size-xs;
  box-sizing: border-box;
  p {
    margin: 0;
    transform: scale(0.9) translateX(-5%);
  }
  .banner-cont {
    float: right;
    display: flex;
    right: 5%;
    bottom: 0.4rem;
    align-items: center;
  }
}
.title-font {
  font-size: 18px;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: space-between;
  background-color: #fff;
  padding: 14px 16px 0;
}
.icon-btn {
  padding: 5px 5px 5px 5px;
  font-size: 20px;
}
.content {
  padding: 16px 16px 90px;
}
.van-cell__right-icon {
  margin-left: 5px;
  color: @blue;
}
.detailtext {
  line-height: 26px;
  font-size: @font-size-md;
  padding-top: 5px;
}
.sheet-cont{
  min-height: 60vh;
  max-height: 80vh;
}
.indent{
  text-indent:2em;
  display: inherit;
}
</style>